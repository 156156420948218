export default {
    currentDomain: 'https://zap.healspan.com',
    apiEndPoint: 'https://zapapi.healspan.com',
    dataDogApplicationIdRUM: '1a2f7e46-21b7-4560-b670-ec47f09a78a1',
    dataDogClientTokenRUM: 'pube0919a26abf7e6d394b29fec4c40b5d9',
    wraprEndPoint: 'https://api.healthica.in/wrapr/',
    wraprMode: 'sandbox',
    show_abdm: false,
    disableABDM: false,
    aesKey: 'Ezb38Siwg4JXBctEDpug3ZWhjBDO52UT',
    logoutTimeInMiliseconds: 172800000
}
